<template>
    <div style="width: 95%;float: left">
        <div class="Header_part">
            <div style="width: 50%; text-align:right;float: left;margin-left: 5%">
                <Input search placeholder="">
                    <Select v-model="searchType" @on-change="initial_data" slot="prepend" style="width: 130px">
                        <Option value="untreated">Untreated</Option>
                        <Option value="pass">Pass</Option>
                    </Select>
                </Input>
            </div>

            <div style="width: 30%; text-align:right;float: right; margin-right: 5%">
                <!--<Dropdown style="margin-left: 20px">
                    <Button type="primary">
                        Request Filter
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list" style="text-align: left">
                        <DropdownItem>Unprocessed</DropdownItem>
                        <DropdownItem>Latest</DropdownItem>
                        <DropdownItem>Approved</DropdownItem>
                        <DropdownItem>Rejected</DropdownItem>
                        <DropdownItem>All</DropdownItem>
                    </DropdownMenu>
                </Dropdown>-->
            </div>
            <!-- 主要是规则，和还能拒绝的数量， 当前的owner-->
        </div>
        <div style="width: 90%; float: left; margin-left: 5%; margin-top: 10px; padding-bottom: 50px">
            <!-- 表格，需要含有的内容 申请人，功能描述，join进程， 若是进行中「显示参与的owner数量和赞成的比例」-->
            <Row style="padding: 1rem; border-radius: 5px 5px 0 0; background-color: #F5F5F5   ; border: #c8e1ff solid 0.0625rem;">
                <Col span="12" style="text-align: left;">
                    <span style="font-size: 1rem; font-weight: 400; margin-left: 0.5rem;">{{ message }}</span>
                </Col>
            </Row>
            <div class="codeTable">
                <List border :data="codeTableData">
                    <ListItem v-for="item in codeTableData" :key="item.id" style="text-align: left; padding-left: 5%">
                        <Avatar icon="ios-person" size="40"/>
                        <ListItemMeta :title="item.userName" :description="item.type" style="text-align: left; padding-left: 5%"/>
                        <template slot="action">
                            <!--<li>
                                <Button v-if="item.applicationType === 1" style="color:#e9ab01">
                                    This application is being audited . . .
                                </Button>
                                <Button v-else-if="item.applicationType === 2"  style="color:#13AF5F">
                                    This application has been approved.
                                </Button>
                                <Button v-else style="color:#D13819">
                                    This application has been rejected.
                                </Button>
                            </li>-->
                            <li>
                                <a :href="'/#/checkFurtherInfo?id='+projectId+ '&jid='+ item.id"> <Icon type="ios-arrow-forward" /> </a>
                            </li>
                        </template>
                    </ListItem>
                </List>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "checkJoinList",
        data(){
            return{
                searchType: 'untreated',
                projectId:'',
                message:'',
                data1: [
                    {
                        branchName: 'main',
                        IsdDefault: '(default)'
                    },
                    {
                        branchName: 'frontend',
                        IsdDefault: ''
                    },
                    {
                        branchName: 'backend',
                        IsdDefault: ''
                    },
                ],
                codeTableData:[

                ]
            }
        },
        methods: {
            initial_data(){
                var Tself = this;
                this.projectId = this.$route.query.id;
                //codeTableData获取所有的申请
                let losww = false;
                if(Tself.searchType==='pass'){
                    losww = true;
                }
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/moreInformation/findByProjectIdAndStatus',
                    data: {
                        projectId: Tself.projectId,
                        solve: losww
                    }
                }).then(function (response) {
                    console.log(response);
                    var items = response.data.data.items;//[{appl1, app2, app3}]
                    var results = items.map(
                        function(obj){
                            var res = {}
                            res['id'] = obj.id;
                            res['userName'] = obj.username;
                            res['type'] = obj.type;
                            res['time'] = obj.time;
                            return res;
                        }
                    );
                    Tself.codeTableData = results;
                }).catch(function (error) {
                    Tself.isMember = false;
                    console.log(error);
                });
            },
            check_detail(name) {
                this.$router.push('/checkFurtherInfo')
            },
        },
        mounted() {
            this.initial_data();
        }
    }
</script>

<style scoped>
    .Header_part{
        width:100%;
        float:left;
        margin-top: 20px;
        margin-bottom: 15px;
    }

</style>
